<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="saveForm"
      >
        <!-- importador de texto -->
        <v-row dense>
          <v-col>
            <v-btn
              class="float-right"
              outlined
              :color="showImportComp ? 'warning' : 'primary'"
              @click="showImportComp = !showImportComp"
              >{{ !showImportComp ? $t("import") : $t("cancel") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense v-if="showImportComp">
          <v-col>
            <TestImportComp :importTest="importTest" />
          </v-col>
        </v-row>
        <!-- pregunta principal -->
        <v-row dense class="mt-2">
          <v-col>
            <v-textarea
              outlined
              :label="$t('write-question')"
              v-model="quizz.question"
              :rules="[(v) => !!v || $t('rule-text-required')]"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- mostrar texto al responder -->
        <v-row dense class="mb-3 ml-1">
          <h2>
            Comentarios al contestar
          </h2>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-textarea
              rows="2"
              outlined
              :label="$t('show-comment-right')"
              v-model="quizz.comment_right"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              rows="2"
              outlined
              :label="$t('show-comment-wrong')"
              v-model="quizz.comment_wrong"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row dense class="mb-4">
          <v-col cols="12">
            <h2>
              {{ $t("create-new-question") }}

              <v-btn
                small
                outlined
                color="success"
                class="float-right"
                @click.prevent="newAnswer"
                >{{ $t("new-answer") }}</v-btn
              >
            </h2>
          </v-col>
        </v-row>

        <div v-for="answer in quizz.question_answers" :key="answer.id">
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="12" md="1">
              <v-text-field
                outlined
                dense
                disabled
                name="answer"
                label="ID"
                v-model="answer.id"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                outlined
                dense
                name="answer"
                :label="$t('write-text')"
                v-model="answer.text"
                :rules="[(v) => !!v || $t('rule-text-required')]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="">
              <v-switch
                class="pt-0 mt-2 float-right float-md-left"
                v-model="answer.right_answer"
                color="green"
                inset
                :label="$t(`${answer.right_answer}`)"
              ></v-switch>
              <v-btn
                align-self="start"
                icon
                color="red"
                x-large
                class="float-left float-md-right"
                @click.prevent="deleteItem(answer)"
              >
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-btn class="float-right" color="success" type="submit">{{
              $t("save")
            }}</v-btn>
            <v-btn
              class="float-right mx-1"
              color="warning"
              @click.prevent="cancelQuestion"
              >{{ $t("cancel") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TestImportComp from "./TestImportComp";
export default {
  name: "new-question-comp",
  props: ["typeId", "data", "cancelQuestion"],
  components: {
    TestImportComp,
  },
  data: () => ({
    showImportComp: false,
    isFormValid: true,
    quizz: {
      question: null,
      comment_right: null,
      comment_wrong: null,
      question_answers: [{ id: 1, text: null, right_answer: false }],
    },
  }),
  methods: {
    importTest(data) {
      console.log("desde el padre", data);
      this.quizz = data.quizz;
      /*  if (data.add) {
        this.saveForm();
      } */
    },
    saveForm() {
      this.$refs.form.validate();

      // si no es válido el form retornamos
      if (!this.isFormValid) {
        return;
      }
      // comprobamos is hay alguna respuesta a true
      const tmp = this.quizz.question_answers.filter((a) => a.right_answer);

      if (tmp.length === 0) {
        console.log("no hay respuestas a true");
        this.mixSetSnackbar("no-true-response", "error");
        return;
      }

      // finalmente emitimos al padre
      const data = {
        data: { question_type_id: this.typeId, ...this.quizz },
        editing: this.data ? this.data.index : null,
      };
      this.$emit("emitAddNewQuestion", data);
    },
    newAnswer() {
      const tmp = {
        id: this.quizz.question_answers.length + 1,
        text: null,
        right_answer: false,
        new: true,
      };
      this.quizz.question_answers.push(tmp);
    },
    deleteItem(answer) {
      console.log("eliminar respuesta", answer);
      // si únicamente hay una respuesta retornamos
      if (this.quizz.question_answers.length === 1) {
        return;
      }
      // filtramos el id que no necesitamos
      this.quizz.question_answers = this.quizz.question_answers.filter(
        (a) => a.id !== answer.id
      );
      // recalculamos los id
      if (!this.data) {
        this.updateAnswerId();
      }
    },
    updateAnswerId() {
      const tmp = this.quizz.question_answers;
      tmp.forEach((t, idx) => {
        t.id = idx + 1;
      });
    },
  },

  mounted() {
    // comprobamos si traemos datos para editar
    if (this.data) {
      console.log("hay datos...", this.data);

      this.quizz = { ...this.data.data };
      console.log(this.quizz);
    }
  },
};
</script>

<style lang="scss" scoped></style>
