<template>
  <div>
    <v-card-title>
      {{ $t("import") }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-textarea flat outlined v-model="importData" rows="10"></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="processing.status">
      <v-alert type="info">
        {{
          `${this.$t("processing-question")}: ${
            this.processing.number
          } ${this.$t("of")} ${this.processing.total}`
        }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-row dense>
        <v-col>
          <v-btn color="warning" class="float-right" @click="generarTest"
            >import</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "TestImportComp",
  props: ["importTest"],
  data: () => ({
    importData: null,
    processing: {
      status: false,
      number: 0,
      total: 0,
    },

    /*     importData: `question:Dentro de las capacidades personales de los emprendedores, podemos
encontrar:
answer: La creatividad.
answer: Todas las respuestas son correctas.
answer: La necesidad de superación.
answer: Los conocimientos profesionales.`, */
  }),
  methods: {
    frame(id, questions) {
      if (this.processing.number === questions.length) {
        console.log("cierro el frame");
        clearInterval(id);
      } else {
        const quizz = createQuestion(questions[this.processing.number]);
        this.importTest({ quizz, add: true });
      }
      this.processing.number++;
      console.log(this.processing.number, questions.length);
    },
    generarTest() {
      // constantes de separacion
      const questionBp = "##";
      let questions = [];

      if (!this.importData) {
        return;
      }

      // primer paso - comprobar si  varias preguntas
      if (this.importData.includes(questionBp)) {
        // console.log("la pregunta tiene separador");
        questions = this.importData.split(questionBp);
        this.processing.total = questions.length;
        this.processing.number = 0;
        this.processing.status = true;

        questions.forEach((q) => {
          setTimeout(() => {
            this.processing.number++;
            const quizz = createQuestion(q);
            this.importTest({ quizz, add: true });
          }, 200);
        });
      } else {
        const quizz = createQuestion(this.importData);
        this.importTest({ quizz, add: true });
      }
    },
    generarTestFrame() {
      // constantes de separacion
      const questionBp = "##";
      let questions = [];

      if (!this.importData) {
        return;
      }

      // primer paso - comprobar si  varias preguntas
      if (this.importData.includes(questionBp)) {
        // console.log("la pregunta tiene separador");
        questions = this.importData.split(questionBp);
        this.processing.total = questions.length;
        this.processing.number = 0;
        this.processing.status = true;
        const id = setInterval(
          this.frame(id, questions),
          3000 / questions.number
        );

        // eslint-disable-next-line no-inner-declarations
        /*  function frame() {
          console.log(this.processing.number);
          if (this.processing.number === questions.length) {
            clearInterval(id);
          } else {
            const quizz = createQuestion(questions[this.processing.number]);
            this.importTest({ quizz, add: true });
            this.processing.number++;
          }
        } */

        console.log("finalizado");
        /*  setTimeout(() => {
          questions.forEach((q) => {
            this.processing.number++;
            const quizz = createQuestion(q);
            this.importTest({ quizz, add: true });
          });
        }, 20); */
      } else {
        const quizz = createQuestion(this.importData);
        this.importTest({ quizz, add: true });
      }
    },
  },
  computed: {
    compProcessText() {
      if (!this.processing.status) {
        return null;
      }
      return `${this.$t("processing-question")}: ${
        this.processing.number
      } ${this.$t("of")} ${this.processing.total}`;
    },
  },
};

const createQuestion = (data) => {
  const questionBp = "##";
  const answerTrue = "$$";
  const jump = [questionBp, answerTrue, ""];
  const tmp = data.split("\n");

  const res = {
    question: null,
    answers: [],
  };

  let lastInsert = "";

  tmp.forEach((t) => {
    const s = t.substring(0, 4);

    if (jump.some((j) => j === t.trim())) {
      // console.log("saltamos...", t);
    } else if (s === "ques") {
      // console.log("es una pregunta", t, index);
      res.question = t.replace("question:", "").trim();
      lastInsert = "question";
    } else if (s === "answ") {
      // console.log("es una respuesta", t, index);
      res.answers.push(t.replace("answer:", "").trim());
      lastInsert = "answers";
    } else {
      //  console.log("es un salto de linea", t, index);
      // es un salto de linea que tenemos que agregar al anterior
      if (lastInsert === "question") {
        let txt = res.question + " " + t.trim();
        res.question = txt;
      } else {
        // es una respuesta
        let re = res.answers[res.answers.length - 1];
        res.answers[res.answers.length - 1] = re + " " + t.trim();
      }
    }
  });
  //console.log(res);
  // convertimos la pregunta
  const tanswer = [];
  res.answers.forEach((a, idx) => {
    // comprobamos si hay alguna respuesta verdadera $$
    let text = a;
    let right_answer = false;
    // comprobamos la respuesta correcta
    if (a.indexOf(answerTrue) > -1) {
      text = a.replace(answerTrue, "");
      right_answer = true;
    }

    tanswer.push({
      id: idx + 1,
      text,
      right_answer,
      new: true,
    });
  });
  return {
    question: res.question,
    comment_right: null,
    comment_wrong: null,
    question_answers: tanswer,
  };
  // console.log(quizz);
  // return quizz;
};
</script>

<style lang="scss" scoped></style>
