import { render, staticRenderFns } from "./TestGeneralDataComp.vue?vue&type=template&id=ab4f6354&scoped=true&"
import script from "./TestGeneralDataComp.vue?vue&type=script&lang=js&"
export * from "./TestGeneralDataComp.vue?vue&type=script&lang=js&"
import style0 from "./TestGeneralDataComp.vue?vue&type=style&index=0&id=ab4f6354&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4f6354",
  null
  
)

export default component.exports