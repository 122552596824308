<template>
  <div v-resize="onResize">
    <v-responsive class="mx-auto mb-4">
      <v-text-field
        v-model="search"
        :label="$t('search')"
        clearable
        :hint="compQuestions.length.toString()"
        persistent-hint
      ></v-text-field>
    </v-responsive>

    <v-card elevation="1" class="mx-auto">
      <v-virtual-scroll
        :bench="5"
        :items="compQuestions"
        :height="windowSize.y - 400"
        item-height="400"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.id">
            <v-list-item-content>
              <v-card>
                <v-row dense>
                  <v-col cols="12" class="text-body-1 mt-5 font-weight-bold">
                    {{ item.question }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-radio-group column>
                      <v-radio
                        v-for="{
                          id,
                          text,
                          right_answer,
                        } in item.question_answers"
                        :key="id"
                        :label="`${text}`"
                        color="red"
                        :value="id"
                        class="py-1 py-md-4 pl-3 text-wrap blue lighten-5"
                        :class="right_answer === 1 ? 'green lighten-1' : ''"
                        active-class="green ligthen-5"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <!--   {{ item }} -->
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TestFilterComp",
  props: ["questions"],
  data: () => ({
    search: null,
    benched: 0,
    windowSize: {
      x: 0,
      y: 0,
    },
  }),
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
  computed: {
    items() {
      return Array.from({ length: this.length }, (k, v) => v + 1);
    },
    length() {
      return 7000;
    },
    compQuestions() {
      if (!this.search) {
        return this.questions;
      } else {
        return this.questions.filter((q) => {
          if (compararRegexStr(this.search, q.question)) {
            return q;
          }

          const f = q.question_answers.filter(
            (a) => compararRegexStr(this.search, a.text)
            // a.text.toUpperCase().includes(this.search.toUpperCase())
          );

          if (f.length > 0) {
            return q;
          }
        });
      }
    },
  },
  mounted() {
    this.onResize();
  },
};

const compararRegexStr = (cadena, texto) => {
  let pattern = texto.toLowerCase();
  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};
</script>

<style lang="scss" scoped></style>
