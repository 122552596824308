<template>
  <v-form v-model="isValid">
    <v-row dense>
      <v-col>
        <v-text-field
          dense
          outlined
          v-model="ldata.name"
          :rules="[(v) => !!v || $t('rule-text-required')]"
          :label="$t('name')"
          required
          clearable
          :autocomplete="`new-temp-${+new Date()}`"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          dense
          outlined
          v-model="ldata.description"
          :rules="[(v) => !!v || $t('rule-text-required')]"
          :label="$t('description')"
          required
          clearable
          :autocomplete="`new-otrote-${+new Date() + 45}`"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-tooltip top color="red">
          <template v-slot:activator="{ on }">
            <v-combobox
              v-on="on"
              v-model="ldata.tags"
              :items="tagItems"
              outlined
              dense
              chips
              small-chips
              :label="$t('tags')"
              multiple
              clearable
              deletable-chips
              :rules="[
                (v) =>
                  (v.length > 0 && v.length <= maxTags) ||
                  $t('rule-text-required'),
              ]"
              :hint="`${$t('max')} ${maxTags} ${$t('tags')}.`"
              persistent-hint
            >
            </v-combobox>
          </template>
          <span>{{ $t("tooltip-help-tags") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-checkbox
          v-model="ldata.random_order"
          :label="$t('random-questions')"
          color="red"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          v-model="ldata.show_one_question"
          :label="$t('show-one-question')"
          color="red"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "TestGeneralDataComp",

  props: ["data", "updateGeneralData"],

  data: () => ({
    maxTags: 4,
    isLoading: true,
    isValid: true,
    ldata: {
      name: null,
      description: null,
      tags: [],
      random_order: false,
      show_one_question: false,
    },
    tagItems: ["DAM", "DAW"],
  }),

  methods: {},

  computed: {
    compData() {
      return this.data;
    },
  },

  watch: {
    ldata: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.ldata.tags.length > this.maxTags) {
          this.ldata.tags.pop();
        }
        if (!this.isLoading) {
          this.updateGeneralData(newValue);
        }
      },
    },
  },

  mounted() {
    this.ldata = { ...this.data };
    this.ldata.tags = this.ldata.tags ? this.ldata.tags.split(",") : [];
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
::v-deep span.v-chip--select {
  border-radius: 5px;
  background-color: blue !important;
  color: white;
  margin-top: 2px !important;
}
::v-deep .v-chip__close {
  color: white;
}
</style>
