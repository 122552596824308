<template>
  <div>
    <v-card>
      <!--   {{ data }} -->
      <v-card-text class="mx-0 pa-0">
        <v-row dense class="mb-2 blue darken-1 white--text py-3 pl-5">
          <v-col>
            <h3 class="text-uppercase">Pregunta {{ index }}</h3>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-textarea
              flat
              outlined
              v-model="compData.question"
              readonly
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-radio-group v-model="response" column>
              <v-radio
                v-for="{ id, text, right_answer } in compData.question_answers"
                :key="id"
                :label="`${text}`"
                color="red"
                :value="id"
                class="py-1 py-md-4 pl-3 text-wrap blue lighten-5"
                :class="right_answer === 1 ? 'green lighten-1' : ''"
                @click="testclick(id)"
                active-class="green ligthen-5"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :small="$vuetify.breakpoint.xsOnly"
              class="float-right ma-1"
              @click="checkQuestion()"
              >{{ $t("check-question") }}</v-btn
            >
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :small="$vuetify.breakpoint.xsOnly"
              class="float-right ma-1"
              @click="resetQuestion()"
              >{{ $t("reset-question") }}</v-btn
            >
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :small="$vuetify.breakpoint.xsOnly"
              class="float-right ma-1"
              @click="editQuestion({ index, typeId: 1 })"
              >{{ $t("edit") }}</v-btn
            >
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              :small="$vuetify.breakpoint.xsOnly"
              class="float-right ma-1"
              @click="deleteQuestion(index)"
              >{{ $t("delete") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text v-if="alertMsg.show">
        <v-row dense>
          <v-col>
            <v-alert :color="alertMsg.responseType" type="info" :value="true">
              {{ alertMsg.text }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TestTypeOneComp",
  props: ["data", "index", "editQuestion", "deleteQuestion"],
  data: () => ({
    response: null,

    alertMsg: {
      show: false,
      responseType: "green",
      text: "",
    },
  }),
  computed: {
    compData() {
      const tmp = this.data;
      // generamos una posición random
      tmp.question_answers = randomPosition(this.data.question_answers);
      return tmp;
    },
    compPosition() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return this.$vuetify.breakpoint.name;
        case "sm":
          return this.$vuetify.breakpoint.name;
        case "md":
          return this.$vuetify.breakpoint.name;
        case "lg":
          return this.$vuetify.breakpoint.name;
        case "xl":
          return this.$vuetify.breakpoint.name;
      }
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    testclick(id) {
      this.response = id;
    },
    checkQuestion() {
      if (this.response) {
        console.log(this.compData);
        const tmp = this.compData.question_answers.filter(
          (x) => x.id === this.response && x.right_answer
        );

        // es verdadera
        if (tmp.length > 0) {
          this.alertMsg = {
            show: true,
            responseType: "green",
            text: this.$t("right-answer"),
          };
        } else {
          this.alertMsg = {
            show: true,
            responseType: "red",
            text: this.$t("error-answer"),
          };
        }
      }
    },
    resetQuestion() {
      this.response = null;
      this.alertMsg = {
        show: false,
        responseType: "green",
        text: "",
      };
    },
  },
};

const randomPosition = (pAnswers) => {
  let answers = [...pAnswers];

  let counter = answers.length;

  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;

    // cambiamos la posición conforme al random
    let temp = answers[counter];
    answers[counter] = answers[index];
    answers[index] = temp;
  }
  return answers;
};
</script>

<style lang="scss" scoped></style>
