<template>
  <v-card v-if="!isLoading">
    <v-card-title
      class="white--text deep-purple accent-4 text-uppercase"
      border="bottom"
    >
      {{ editing ? $t("editing-test") : $t("create-new-test") }}
    </v-card-title>
    <v-tabs
      background-color="deep-purple accent-4"
      center-active
      dark
      v-model="tab"
    >
      <v-tab>{{ $t("general") }}</v-tab>
      <v-tab>
        <v-badge
          color="black"
          class="text--white"
          :content="data.question_test.length.toString()"
        >
          {{ $t("questions") }}
        </v-badge>
      </v-tab>
      <v-tab>
        {{ $t("search") }}
      </v-tab>
      <!--   <v-tab>{{ $t("config") }}</v-tab>
      <v-tab>{{ $t("preview") }}</v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!--PESTAÑA : DATOS DE LA PESTAÑA GENERAL -->
      <v-tab-item>
        <v-card-text>
          <TestGeneralDataComp
            :data="data"
            :updateGeneralData="updateGeneralData"
          />
        </v-card-text>
      </v-tab-item>

      <!-- PESTAÑA : preguntas -->
      <v-tab-item>
        <!-- seccion preguntas -->
        <v-card-title>
          <v-row dense>
            <v-col cols="12">
              <div v-if="!showNewQuestion">
                <v-btn
                  class="float-right"
                  color="deep-purple accent-4"
                  dark
                  outlined
                  @click="newQuestion(1)"
                >
                  {{ $t("new-question") }}
                </v-btn>
              </div>
              <v-btn
                v-if="showNewQuestion"
                class="float-right"
                color="purple accent-4"
                outlined
                dark
                @click="showNewQuestion = !showNewQuestion"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                v-if="!showNewQuestion"
                class="float-right mr-1"
                outlined
                :color="showImportComp ? 'warning' : 'primary'"
                @click="showImportComp = !showImportComp"
                >{{ !showImportComp ? $t("import") : $t("cancel") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <!-- buscador -->
        <!--  <v-card-text>
          <v-row dense>
            <v-col>
              <v-text-field
                dense
                outlined
                v-model="search"
                :label="$t('search')"
                clearable
                :autocomplete="`new-temp-${+new Date()}`"
                :hint="compQuestionsFiltered.length.toString()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text> -->

        <!-- IMPORT -->
        <v-card-text class="py-2">
          <!--  <v-row dense v-if="!showNewQuestion">
            <v-col>
              <v-btn
                class="float-right"
                outlined
                :color="showImportComp ? 'warning' : 'primary'"
                @click="showImportComp = !showImportComp"
                >{{ !showImportComp ? $t("import") : $t("cancel") }}</v-btn
              >
            </v-col>
          </v-row> -->

          <v-row dense v-if="showImportComp">
            <v-col>
              <TestImportComp :importTest="importTest" />
            </v-col>
          </v-row>
        </v-card-text>

        <!-- AQUI LAS PREGUNTAS -->
        <v-card-text>
          <div id="questionComp">
            <component
              v-if="showNewQuestion"
              :is="compComponent"
              :typeId="newQuestionId"
              :data="editingQuestionData"
              :cancelQuestion="() => (showNewQuestion = false)"
              @emitAddNewQuestion="addNewQuestion"
            ></component>
          </div>
        </v-card-text>

        <!-- DIVIDER -->
        <v-divider class="mx-1"></v-divider>

        <!-- PREVIEW -->
        <v-row>
          <v-col cols="12">
            <v-card-title
              v-if="compQuestionsFiltered.length > 0"
              class="text-uppercase"
              border="bottom"
            >
            </v-card-title>
            <div
              v-for="(question, index) in compQuestionsFiltered"
              :key="question.id"
            >
              <component
                :is="question.question_type_id | fComponent"
                :data="question"
                :index="index + 1"
                :editQuestion="editQuestion"
                :deleteQuestion="deleteQuestion"
              ></component>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
      <!-- PESTAÑA : SEARCH -->
      <v-tab-item>
        <v-card-text>
          <test-filter-comp :questions="data.question_test"></test-filter-comp>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="mx-1"></v-divider>
    <v-card-actions>
      <v-row dense class="mt-2">
        <v-col cols="12">
          <v-btn
            class="float-right"
            outlined
            color="secondary"
            dark
            @click="saveTest()"
          >
            {{ $t("save-test") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <!--<pre>

    {{ data }}
    </pre> -->
  </v-card>
</template>

<script>
import TestImportComp from "./parts/TestImportComp";
import TestGeneralDataComp from "@/components/test/parts/TestGeneralDataComp.vue";
import TestOneQuestionComp from "@/components/test/parts/TestOneQuestionComp.vue";
import TestMultiQuestionComp from "@/components/test/parts/TestMultiQuestionComp.vue";
// import de los componentes para las preview
import TestTypeOnePreviewComp from "@/components/test/parts/TestTypeOnePreviewComp.vue";
import TestFilterComp from "@/components/test/parts/TestFilterComp";
import { testSrv } from "@/_services/test.service.js";

export default {
  name: "TestEditMainComp",
  components: {
    TestOneQuestionComp,
    TestMultiQuestionComp,
    TestTypeOnePreviewComp,
    TestGeneralDataComp,
    TestImportComp,
    TestFilterComp,
  },
  /*   mixins: [loaderMixin, snackbarMixin], */
  data: () => ({
    showImportComp: false,
    search: null,
    editing: false,
    isLoading: true,
    tab: 1,
    testTypes: [
      { id: 1, name: "one-question-true" },
      { id: 2, name: "multi-question-true" },
      { id: 3, name: "one-selector" },
      { id: 4, name: "multi-selector" },
    ],
    showNewQuestion: false,
    editingQuestionData: null,
    newQuestionId: null,
    data: {
      name: null,
      description: null,
      user_id: 1,
      random_order: false,
      show_one_question: false,
      tags: null,
      question_test: [],
    },
  }),

  methods: {
    importTest(importData) {
      this.data.question_test.push({
        question_type_id: 1,
        ...importData.quizz,
      });
    },
    newQuestion(testType) {
      // //console.log("nueva pregunta ", testType);
      // this.newQuestionId = testType.id;
      this.newQuestionId = testType;
      this.showNewQuestion = true;
    },
    addNewQuestion(data) {
      if (data.editing !== null && data.editing >= 0) {
        //console.log("editando", data.editing);
        this.data.question_test.splice(data.editing, 1, { ...data.data });
        delete data.editing;
      } else {
        //console.log("es nuevo");
        this.data.question_test.push(data.data);
      }
      this.showNewQuestion = false;
    },
    editQuestion({ index, typeId }) {
      console.log(index);

      this.editingQuestionData = {
        data: this.data.question_test[index - 1],
        index: index - 1,
      };
      //console.log("editando la question", this.editingQuestionData);
      this.newQuestionId = typeId;
      this.showNewQuestion = true;
      let target = document.querySelector("#questionComp");

      this.$vuetify.goTo(target, { duration: 300 });
    },
    deleteQuestion(index) {
      //console.log("desde el padreee", index);
      this.data.question_test.splice(index - 1, 1);
    },
    updateGeneralData({
      name,
      description,
      random_order,
      tags,
      show_one_question,
    }) {
      this.data.name = name;
      this.data.description = description;
      this.data.tags = tags.join(",");
      this.data.random_order = random_order;
      this.data.show_one_question = show_one_question;
    },
    async saveTest() {
      if (!this.data.name || this.data.name.length < 1) {
        this.mixSetSnackbar("name-required", "error");
        return;
      }

      if (!this.data.description || this.data.description.length < 1) {
        this.mixSetSnackbar("description-required", "error");
        return;
      }

      if (!this.data.tags || this.data.tags.length < 1) {
        this.mixSetSnackbar("tags-required", "error");
        return;
      }

      try {
        if (this.editing) {
          await testSrv.updateTest(this.data);
          this.mixSetSnackbar("updated-success", "success");
        } else {
          await testSrv.createTest(this.data);
          this.mixSetSnackbar("created-success", "success");
        }
      } catch (error) {
        this.mixSetSnackbar(
          this.editing ? "updated-error" : "created-error",
          "error"
        );
      }
    },
  },
  computed: {
    compQuestionsFiltered() {
      // return this.data.question_test;
      // const limite = 20;
      if (!this.search) {
        return this.data.question_test;
      } else {
        return this.data.question_test.filter((q) => {
          if (compararRegexStr(this.search, q.question)) {
            return q;
          }

          const f = q.question_answers.filter(
            (a) => compararRegexStr(this.search, a.text)
            // a.text.toUpperCase().includes(this.search.toUpperCase())
          );

          if (f.length > 0) {
            return q;
          }
        });
      }
    },
    compComponent() {
      switch (Number(this.newQuestionId)) {
        case 1:
          return TestOneQuestionComp;
        case 2:
          //console.log("llego al 2");
          return TestMultiQuestionComp;
        default:
          return null;
      }
    },
  },
  filters: {
    fComponent: function(value) {
      switch (Number(value)) {
        case 1:
          return TestTypeOnePreviewComp;
        case 2:
          //console.log("llego al 2");
          return TestMultiQuestionComp;
        default:
          return null;
      }
    },
  },
  watch: {
    showNewQuestion(newValue) {
      if (newValue === false) {
        //console.log("watches", newValue);
        this.editingQuestionData = null;
      }
    },
  },
  async mounted() {
    const id = this.$route.params.id;
    //console.log(id);
    // si hay id es editar
    if (id) {
      this.mixShowOverlay();
      const result = await testSrv.getTestById(id);
      //console.log(result.data);
      this.data = result.data;
      this.editing = true;
      this.mixHideOverlay();
    }
    this.isLoading = false;
  },
};

const compararRegexStr = (cadena, texto) => {
  let pattern = texto.toLowerCase();
  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};
</script>

<style lang="scss" scoped></style>
