<template>
  <v-card>
    <v-card-text>
      <v-form
        ref="form"
        v-model="isFormValid"
        lazy-validation
        @submit.prevent="saveForm"
      >
        <v-row dense>
          <v-col cols="12">
            <h2>
              {{ $t("create-new-multi-question") }}

              <v-btn
                small
                outlined
                color="success"
                class="float-right"
                @click="newAnswer"
                >{{ $t("new-answer") }}</v-btn
              >
            </h2>
          </v-col>
        </v-row>
        <v-row dense class="mt-2">
          <v-col>
            <v-textarea
              outlined
              name="input-7-4"
              :label="$t('write-question')"
              v-model="quizz.question"
              :rules="[(v) => !!v || $t('rule-text-required')]"
            ></v-textarea>
          </v-col>
        </v-row>

        <div v-for="answer in quizz.answers" :key="answer.id">
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="12" md="1">
              <v-text-field
                outlined
                dense
                disabled
                name="answer"
                label="ID"
                v-model="answer.id"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                outlined
                dense
                name="answer"
                :label="$t('write-text')"
                v-model="answer.text"
                :rules="[(v) => !!v || $t('rule-text-required')]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="">
              <v-switch
                class="pt-0 mt-2 float-right float-md-left"
                v-model="answer.isTrue"
                color="green"
                inset
                :label="$t(`${answer.isTrue}`)"
              ></v-switch>
              <v-btn
                align-self="start"
                icon
                color="red"
                x-large
                class="float-left float-md-right"
                @click="deleteItem(answer)"
              >
                <v-icon>mdi-delete-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-btn color="success" type="submit">guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TestMultiQuestionComp",
  props: ["typeId"],
  data: () => ({
    isFormValid: true,
    quizz: {
      question: null,
      answers: [{ id: 1, text: null, isTrue: false }],
    },
  }),
  methods: {
    saveForm() {
      this.$refs.form.validate();

      // si no es válido el form retornamos
      if (!this.isFormValid) {
        return;
      }
      // comprobamos is hay alguna respuesta a true
      const tmp = this.quizz.answers.filter((a) => a.isTrue);

      if (tmp.length < 1) {
        console.log("no hay respuestas a true");
        return;
      }

      // finalmente emitimos al padre
      const data = {
        typeId: this.typeId,
        data: this.quizz,
      };
      this.$emit("emitAddNewQuestion", data);
    },
    newAnswer() {
      const tmp = {
        id: this.quizz.answers.length + 1,
        text: null,
        isTrue: false,
      };
      this.quizz.answers.push(tmp);
    },
    deleteItem(answer) {
      console.log("eliminar respuesta", answer);
      // si únicamente hay una respuesta retornamos
      if (this.quizz.answers.length === 1) {
        return;
      }
      // filtramos el id que no necesitamos
      this.quizz.answers = this.quizz.answers.filter((a) => a.id !== answer.id);
      // recalculamos los id
      this.updateAnswerId();
    },
    updateAnswerId() {
      const tmp = this.quizz.answers;
      tmp.forEach((t, idx) => {
        t.id = idx + 1;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
